import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import './course-name.css';



const CourseName = () => {

    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [inputValue, setInputValue] = useState('');



    const fetchSchoolName = async () => {
        const { token } = loginState.data;
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/name/1`;
            const response = await callApi('GET', url, token);
            setInputValue(response.schoolName);
        } catch (error) {
            console.error('Error fetching school name:', error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const confirmChange = window.confirm('Do you want to change the course name?');
        if (confirmChange) {
            const { token } = loginState.data;
            try {
                const url = `${process.env.REACT_APP_API_URL}/v1/school/update-name`;
                const requestBody = { newName: inputValue };
                await callApi('PUT', url, token, requestBody);
                await fetchSchoolName();
            } catch (error) {
                console.error('Error updating school name:', error);
            }
        }
    };



    useEffect(() => { fetchSchoolName(); }, []);



    return (
        <form className="dashboard-course-name" onSubmit={handleSubmit}>
            <div>Course Name:</div>
            <input
                type="text" value={inputValue} onChange={handleInputChange}
                className='course-name-input' placeholder='Course Name'
            />
            <button type="submit" className="course-name-submit">Submit</button>
        </form>
    );
};

export default CourseName;
