import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import { StudentInfo } from '../../store/approval/types';
import PulseLoader from 'react-spinners/PulseLoader';
import userImage from '../../images/user/user-image.png';



const UserTableSearch = () => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const { token } = useSelector((state: ApplicationState) => state.autherize.data);
    const [keyword, setKeyword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<StudentInfo[]>([]);



    const searchUsers = async () => {
        if (keyword.trim().length < 3) return alert('Keyword must be at least 3 characters long.');

        setLoading(true);
        try {
            const response: StudentInfo[] = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/users/search?keyword=${encodeURIComponent(keyword)}`, token);
            const approvedIds = new Set(approvedList.map((user) => user.id));
            setSearchResults(response.filter((user) => !approvedIds.has(user.id)));
        } catch (error) {
            console.error('Error searching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async (id: string) => {
        try {
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/school/school-room/insert`, token, { userId: id });
            dispatch(fetchApprovedListRequest()); setSearchResults([]);
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleClearSearch = () => {
        setKeyword(''); setSearchResults([]);
    };

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === 'Enter') searchUsers() };



    return (
        <div>
            <div className="playlist-search-container">
                <input
                    type="text" value={keyword} placeholder="Search User"
                    onChange={handleKeywordChange} onKeyDown={handleKeyDown}
                />
                <button onClick={searchUsers}>Search</button>
                {searchResults.length > 0 && <button onClick={() => handleClearSearch()} className="clear-button">Clear</button>}
            </div>

            {loading ? (
                <div className="loader-container">
                    <PulseLoader color="#0b7aff" />
                </div>
            ) : searchResults.length > 0 && (
                <table className="table-container">
                    <thead>
                        <tr>
                            <th className="table-id">ID</th>
                            <th className="table-name">Name</th>
                            <th className="table-add">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map((user) => (
                            <tr key={user.id}>
                                <td className="table-id">{user.id}</td>
                                <td>
                                    <img className="table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                    {user.fullname}
                                </td>
                                <td className="table-add">
                                    <button className="playlist-add-button" onClick={() => handleAdd(user.id)}>
                                        Add
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default UserTableSearch;
