import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { fetchPendingListRequest, deleteStudentRequest, fetchApprovedListRequest } from '../../store/approval/actions';
import { StudentInfo } from '../../store/approval/types';
import './user-table-pending.css';



const UserPendingList = () => {

    const dispatch = useDispatch();
    const pendingList = useSelector((state: ApplicationState) => state.approval.pendingList?.data || []);
    const { token } = useSelector((state: ApplicationState) => state.autherize.data);



    const handleRejectClick = (data: StudentInfo) => dispatch(deleteStudentRequest(data));

    const handleAdd = async (id: string) => {
        try {
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/school/school-room/insert`, token, { userId: id });
            dispatch(fetchApprovedListRequest()); dispatch(fetchPendingListRequest());
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };



    useEffect(() => { dispatch(fetchPendingListRequest()); }, []);



    if (pendingList.length === 0) return null;

    return (
        <div className="pending-list-table-wrapper">
            <h3 className="pending-list-header dashboard-header">
                Pending List
                <span className="pending-list-tooltip">กดซื้อ Premium Plus แล้ว<br />แต่รอยืนยันชำระเงิน</span>
            </h3>
            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-id">ID</th>
                        <th className="user-table-name">Name</th>
                        <th className="user-table-add">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingList.length ? (
                        pendingList.map((user) => (
                            <tr key={user.id}>
                                <td className="user-table-id">{user.id}</td>
                                <td className="user-table-name">{user.fullname}</td>
                                <td className="user-table-add">
                                    <button className="playlist-add-button" onClick={() => handleAdd(user.id)}>
                                        Add
                                    </button>
                                    <button className="remove-button" onClick={() => handleRejectClick(user)}>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr><td colSpan={3} className="no-playlists">No pending users available</td></tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default UserPendingList;
