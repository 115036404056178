export const mapGrade = (grade: string): string => {
    const gradeMap: { [key: string]: string } = {
        'E4': 'ป.4',
        'E5': 'ป.5',
        'E6': 'ป.6',
        'J1': 'ม.1',
        'J2': 'ม.2',
        'J3': 'ม.3',
    };
    return gradeMap[grade] || 'Unknown';
};
