import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';
import './livestream-modal.css';

interface QuizAnswer { question_id: number; correct_choice: string; user_choice: string; }
interface QuizPercentage { total_count: number; correct_count: number; }



const ModalLiveQuiz = (props: {
    isOpen: boolean, onClose: () => void, fullname: string, userId: string, liveId: number, subject: string, startTime: string
}) => {

    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const playlists = useSelector((state: ApplicationState) => state.toggle.playlists);
    const toggleState = useSelector((state: ApplicationState) => state.toggle);

    const [loading, setLoading] = useState(true);
    const [percentageData, setPercentageData] = useState<Record<number, number>>({});
    const [quizData, setQuizData] = useState<QuizAnswer[]>([]);
    const [questions, setQuestions] = useState<{ question_id: number }[]>([]);

    const playlistId = toggleState.toggle.split('-')[1];
    const totalQuestions = questions.length;

    const correctAnswers = quizData.filter(answer => answer.correct_choice === answer.user_choice).length;
    const currentPlaylist = playlists.find(playlist => playlist.id == playlistId)?.name || 'Unknown Playlist';
    const currentInstructor = playlists.find(playlist => playlist.id == playlistId)?.instructor || 'Unknown Instructor';



    const fetchCorrectAnswers = async (liveId: number, userId: string) => {
        try {
            setLoading(true);
            const { token } = loginState.data;
            const url = `${process.env.REACT_APP_API_URL}/v1/school/live-quiz/correct-answers?liveId=${liveId}&userId=${userId}`;
            const data: QuizAnswer[] = await callApi('GET', url, token);
            const questionIds = Array.from(new Set(data.map(item => item.question_id))).sort((a, b) => a - b);

            await handlePercentage(questionIds);
            setQuizData(data);
            setQuestions(questionIds.map(id => ({ question_id: id })));

        } catch (error) {
            console.error('Error fetching correct answers:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePercentage = async (questionIds: number[]) => {
        const percentages = await Promise.all(
            questionIds.map(async (questionId) => {
                const data = await fetchPercentageData(questionId);
                const percentage = data ? (data.correct_count / data.total_count) * 100 : 0;
                return { questionId, percentage };
            })
        );
        const percentageMap: Record<number, number> = {};
        percentages.forEach(({ questionId, percentage }) => { percentageMap[questionId] = percentage });
        setPercentageData(percentageMap);
    };

    const fetchPercentageData = async (questionId: number) => {
        try {
            const { token } = loginState.data;
            const url = `${process.env.REACT_APP_API_URL}/v1/school/live-quiz/percentage-stats?questionId=${questionId}`;
            const data: QuizPercentage = await callApi('GET', url, token);
            return data;
        } catch (error) {
            console.error('Error fetching percentage data:', error);
            return null;
        }
    };

    const formatStartTime = (starttime: string) => moment(starttime).format('DD MMM, YY');



    useEffect(() => {
        if (props.isOpen) fetchCorrectAnswers(props.liveId, props.userId);
    }, [props.isOpen, props.liveId]);



    if (!props.isOpen) return null;

    return (
        <div className="livestream-modal-overlay" onClick={props.onClose}>
            <div className="livestream-modal-content" onClick={(e) => e.stopPropagation()}>

                <div className="livestream-modal-header">
                    <p><b>Live Quiz</b> – {props.fullname}</p>
                    <p><b>Course</b> – {currentPlaylist}</p>
                    <div className="livestream-header-row">
                        <p><b>Lesson</b> – {props.subject} {`(${formatStartTime(props.startTime)})`}</p>
                        <p><b>Teacher</b> – {currentInstructor}</p>
                    </div>
                </div>

                {loading ? (
                    <div className="livestream-modal-loading-spinner"><PulseLoader color="#0b7aff" /></div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Correct Answer</th>
                                <th>Correct Percentage</th>
                                <th>Student's Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questions.length === 0 ? (
                                <tr>
                                    <td colSpan={4} className="empty-message">No data available</td>
                                </tr>
                            ) : (
                                questions.map(({ question_id }, index) => {
                                    const answer = quizData.find(item => item.question_id === question_id);
                                    const percentage = percentageData[question_id];
                                    const isCorrect = answer && answer.user_choice === answer.correct_choice;
                                    const answerClass = answer && answer.user_choice
                                        ? (isCorrect ? 'livestream-modal-correct-answer' : 'livestream-modal-incorrect-answer')
                                        : '';

                                    return (
                                        <tr key={question_id}>
                                            <td className="livestream-modal-table-single">
                                                {index + 1}
                                            </td>
                                            <td className="livestream-modal-table-single">
                                                {answer ? answer.correct_choice : '-'}
                                            </td>
                                            <td className="livestream-modal-table-single">
                                                {percentage ? `${percentage.toFixed(2)}%` : '0%'}
                                            </td>
                                            <td className={`livestream-modal-table-single ${answerClass}`}>
                                                {answer && answer.user_choice ? answer.user_choice : '-'}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                        {questions.length > 0 && (
                            <tfoot className="livestream-modal-table-footer">
                                <tr>
                                    <td colSpan={2} className="livestream-modal-footer-logo">
                                        <img className="livestream-modal-logo-small" src="/nockacademy-logo.png" alt="NockAcademy Logo" />
                                    </td>
                                    <td>Score</td>
                                    <td>{correctAnswers}/{totalQuestions}</td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                )}
            </div>
        </div>
    );
};

export default ModalLiveQuiz;
