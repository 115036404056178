import React from 'react';
import { Playlist } from '../../store/toggle/types';
import PulseLoader from 'react-spinners/PulseLoader';



const PlaylistTableSearch = (props: {
    keyword: string, setKeyword: React.Dispatch<React.SetStateAction<string>>,
    searchResults: Playlist[], loading: boolean,
    searchPlaylists: () => void, clearSearch: () => void, handleAdd: (id: string) => void
}) => {


    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => props.setKeyword(event.target.value);

    const handleKeywordKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && props.keyword.trim()) props.searchPlaylists();
    };



    return (
        <>
            <div className="playlist-search-container">
                <input
                    type="text" value={props.keyword} placeholder="Search Playlist"
                    onChange={handleKeywordChange} onKeyDown={handleKeywordKeyDown}
                />
                <button onClick={props.searchPlaylists}>Search</button>
                {!props.loading && props.searchResults.length > 0 && (
                    <button onClick={props.clearSearch} className="clear-button">Clear</button>
                )}
            </div>

            <div>
                {props.loading && !props.searchResults.length && (
                    <div className="loader-container">
                        <PulseLoader color="#0b7aff" />
                    </div>
                )}

                {props.searchResults.length > 0 && (
                    <table className="table-container">
                        <thead>
                            <tr>
                                <th className="table-id">ID</th>
                                <th className="table-instructor">Instructor</th>
                                <th className="table-name">Name</th>
                                <th className="table-add">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.searchResults.map((playlist) => (
                                <tr key={playlist.id}>
                                    <td className="table-id">{playlist.id}</td>
                                    <td className="table-instructor">{playlist.instructor}</td>
                                    <td>{playlist.name}</td>
                                    <td className="table-add">
                                        <button className="playlist-add-button" onClick={() => props.handleAdd(playlist.id)}>Add</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
};

export default PlaylistTableSearch;
