import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import StudentFilter from '../../containers/filter/student-filter';
import './table.css';
import './overview-user-table.css';
import userImage from '../../images/user/user-image.png';

interface OverviewUserTableProps {
    selectedUserId: number | null;
    setSelectedUserId: (id: number | null) => void;
}



const OverviewUserTable: React.FC<OverviewUserTableProps> = ({ selectedUserId, setSelectedUserId }) => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const [searchQuery, setSearchQuery] = useState('');

    const sortedList = approvedList.data
        ? [...approvedList.data]
            .filter((user) => {
                const lowerCaseSearchTerm = searchQuery.toLowerCase();
                const fieldsToSearch = [user.id?.toString(), user.fullname, user.login];
                return fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm));
            })
            .sort((a, b) => a.fullname.localeCompare(b.fullname))
        : [];



    const handleRowClick = (userId: number) => {
        if (userId !== selectedUserId) {
            setSelectedUserId(userId);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };



    useEffect(() => { dispatch(fetchApprovedListRequest()); }, [dispatch]);



    return (
        <div className="overview-user-table-container">
            <div className="filter-container">
                <input
                    type="text" placeholder="Search by id or name" className="livestream-table-search-box"
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                />
                <StudentFilter />
            </div>

            <table className="table-container overview-user-table">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="livestream-table-name">Name</th>
                        <th className="livestream-table-centered">Nickname</th>
                        <th className="livestream-table-centered">Grade</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.length > 0 ? (
                        sortedList.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const userId = Number(user.id);
                            const isSelected = selectedUserId === userId;
                            return (
                                <tr
                                    key={user.id}
                                    className={`overview-user-table-row ${isSelected ? 'selected' : ''}`}
                                    onClick={() => handleRowClick(userId)}
                                >
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td>
                                        {user.fullname}
                                    </td>
                                    <td className={`livestream-table-centered`}>
                                        {nickname}
                                    </td>
                                    <td className="livestream-table-centered">
                                        {mapGrade(user.grade)}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={3} className="no-playlists">No Users Available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OverviewUserTable;